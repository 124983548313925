<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>Add LMC online payment</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Use this interface to already paid online payment.</h4>
      </v-card-subtitle>

      <v-card-text>
        <div
          class="d-flex justify-content-center mb-3"
          v-if="ProgessStart == 1"
        >
          <b-spinner
            variant="primary"
            type="grow"
            label="We are fetching details, please wait..."
          ></b-spinner>
        </div>

        <v-container>
          <br />

          <!-- <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <br />
              <v-btn color="blue" class="mr-4" @click="refreshPageData">
                Refresh
              </v-btn>
              <p></p>
            </v-col>
          </v-row> -->

          <v-container class="py-0">
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              id="createForm"
              v-on:submit.prevent="searchForm"
            >
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <v-text-field
                    v-model="PaymentId"
                    :rules="PaymentIdRules"
                    label="Payment Id"
                    hint="Enter the payment id"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="6" lg="3" md="3">
                  <v-text-field
                    v-model="OrderId"
                    :rules="OrderIdRules"
                    label="Order Id"
                    hint="Enter the order id"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col> -->
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert"
                    :loading="SubmitFlag"
                    color="#ff7f50"
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import MemberEdit from "@/view/pages/members/MemberEdit.vue";

export default {
  mixins: [common],
  components: {
    MemberEdit,
  },
  data() {
    return {
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: 0,
      InstructionFlag: 0,

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid1: true,
      valid2: true,

      PaymentId: "",
      PaymentIdRules: [(v) => !!v || "Payment id is required"],

      OrderId: "",
      OrderIdRules: [(v) => !!v || "Order id is required"],

      lastDate: "15-JAN-2021 11:59 PM",

      RoleId: "",

      ResultFlag: 0,
      SearchFlag: false,
      SubmitFlag: false,
      BtnShowFlag: 0,

      NewMembers: [],

      bgColor: "#778899",
      position: "top-right",
      fabActions: [
        /*
        {
          name: 'deleteBtn',
          icon: 'delete',
          color: 'red',
        },
        */
        {
          name: "previewBtn",
          icon: "search",
          color: "orange",
        },
      ],

      singleSelect: true,
      selected1: [],
      selected2: [],
      search1: "",
      search2: "",
      loading: true,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordToEdit: "",

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "", value: "actions", sortable: false, groupable: false },
        { text: "Status", value: "ActiveStatusTxt", groupable: false },
        { text: "Register No", value: "AwardsRegisterNo" },
        { text: "Zone Name", value: "ZoneName" },
        { text: "LOM", value: "LomName" },
        { text: "Award Type", value: "AwardsTypeTitle" },
        { text: "Token", value: "AwardsToken", groupable: false },
        {
          text: "President Details",
          value: "LomPresidentName",
          groupable: false,
        },
        {
          text: "100% Efficiency",
          value: "FullEfficiencyPoints",
          groupable: false,
        },
        { text: "Score", value: "TotalAssessmentScore", groupable: false },
      ],
      rows: [],
      Member: {},
      Members: [],
      TotalMembers: 0,

      tableColumns1: [],
      tableColumns2: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    editAlert() {
      console.log("editAlert called");
      var tr = this.Member;
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        // var CityId = tr.CityId;
        this.recordToEdit = tr.MemberId;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to continue the process?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `No`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lmc/title-contribution/add-online-payment";
        var upload = {
          UserInterface: 1,
          PaymentId: this.PaymentId,
          OrderId: this.OrderId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.$refs.form1.reset();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "Username should not be empty.";
        this.sweetAlert("error", message, false);
      }
    },
    addItem() {
      console.log("addItem called");
      this.dialog = true;
    },
    initialize() {
      this.rows = [];
    },

    editItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialog = true;
    },

    deleteItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.rows.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.rows[this.editedIndex], this.editedItem);
      } else {
        this.rows.push(this.editedItem);
      }
      this.close();
    },
  },
  beforeMount() {
    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    if (RoleId == 1 || RoleId == 2 || RoleId == 3) {
      this.refreshPageData();
      this.DeleteFlag = true;

      if (RoleId == 1 || RoleId == 2 || RoleId == 4) {
        /*
        this.tableColumns1.push(
          { label: "Status", field: "ActiveStatusTxt" },
          { label: "Register No", field: "AwardsRegisterNo" },
          { label: "Zone Name", field: "ZoneName" },
          { label: "LOM", field: "LomName" },
          { label: "Award Type", field: "AwardsTypeTitle" },
          { label: "Token", field: "AwardsToken" },
          { label: "President Details", field: "LomPresidentDetails" },
          { label: "100% Efficiency", field: "FullEfficiencyPoints" },
          { label: "Judges Score", field: "TotalJudgeScoreTxt" },
          { label: "Score", field: "TotalAssessmentScore" }
        );
        */
        this.tableColumns1.push(
          { text: "#", value: "sno" },
          { text: "Member Id", value: "MembershipId" },
          { text: "Member Name", value: "MemberName" },
          { text: "Mobile No.", value: "MobileNo" },
          { text: "Email", value: "EmailId" },
          { text: "Address", value: "AddressTxt" },
          { text: "Area", value: "Area" },
          { text: "City", value: "CityName" },
          { text: "State", value: "StateName" },
          { text: "actions", value: "actions" }
        );

        this.tableColumns2.push(
          { text: "#", value: "sno" },
          { text: "Member Id", value: "MembershipId" },
          { text: "Member Name", value: "MemberName" },
          { text: "Mobile No.", value: "MobileNo" },
          { text: "Email", value: "EmailId" },
          { text: "Address", value: "AddressTxt" }
        );
      } else {
        /*
        this.tableColumns1.push(
          { text: "Status", value: "ActiveStatusTxt" },
          { text: "Register No", value: "AwardsRegisterNo" },
          { text: "Award Type", value: "AwardsTypeTitle" },
          { text: "President Details", value: "LomPresidentDetails" },
          { text: "100% Efficiency", value: "FullEfficiencyPoints" },
          { text: "Actions", value: "actions" }
        );
        */
      }
    } else {
      this.$router.push("/access-denied");
    }
  },
};
</script>

<style lang="scss">
</style>
